@charset "utf-8";
@import "_format";
@import "_variables";
@import "_mixin";
//--- font ---//
 @import url('https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,500,700&display=swap&subset=japanese');

body {
  font-family: 'Noto Sans JP', "ヒラギノ角ゴ Pro W3", "メイリオ", Meiryo, "游ゴシック体", "Yu Gothic", YuGothic, "ＭＳ Ｐゴシック", sans-serif;
  color: #000000;
  background: #ffffff;
}

* {margin: 0; padding: 0; outline: 0;}
html * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

    }
html, body {
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
}
p, a {
font-size: 16px; 
line-height: 1.7em;
word-break: normal;
color: #000;
@media #{$sp} {
	font-size: 15px; 
	}
}
img {
	max-width: 100%; 
	height:auto;
}

//--- anchor ---//
a {
  text-decoration: none;
  cursor: pointer;
  backface-visibility: hidden;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
a:hover, a:hover img, .link:hover {
	opacity: 0.7;
	-moz-opacity: 0.7;
    }
.anchor {
  padding-top: 80px;
  margin-top: -80px;
	@media #{$tab} {
	 padding-top: 110px;
  	 margin-top: -110px;
	}
}
.tel_a:hover {
	cursor: text;
	opacity: 1;
	@media #{$tab} {
	cursor: pointer;
	}
	@media #{$sp} {
	cursor: pointer;
	}
}
//--- font ---//
.font_b {
  font-weight: bold;
}
.c_org {
	color: $clr-org;
}
//--- Layout ---//
.pc { display: block !important; }
.tab { display: none !important; }
.sp { display: none !important; }
.pc_tab { display: block !important; }
.tab_sp { display: none !important; }
@media #{$tab} {
.pc { display: none !important; }
.pc_tab { display: block !important; }
.tab { display: block !important; }
.tab_sp { display: block !important; }
.sp { display: none !important; }
}

@media #{$sp} {
.pc { display: none !important; }
.pc_tab { display: none !important; }
.tab { display: none !important; }
.tab_sp { display: block !important; }
.sp { display: block !important; }
}
.flR {float: right;}
.flL {float: left;}
.clearfix::after {
    content: "";
    display: block;
    clear: both;
    }
//--- IE ---//
@media all and (-ms-high-contrast: none) {
	p, a {
	margin-top: 2px;	
	}
}

input[type=checkbox]::-ms-check {
 display:none;
 -webkit-appearance: none;
 -moz-appearance: none;
 appearance: none;
}
//--- animation ---//
@keyframes LeftToRight {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

 .kv_cat {
  animation-duration: 2s;
  animation-name: LeftToRight;
}

@keyframes float {
  0% { transform: translateY(0) }
  33.33333% { transform: translateY(-10px) }
  66.66667% { transform: translateY(0) }
  100% { transform: translateY(0) }
}
.img_box {
  animation: float 3s linear infinite;
  transform-origin: 50% 50%;
  margin: 1rem 0 !important;
}
 
