@charset "UTF-8";


/* ********************************************************
	共通初期化CSS
******************************************************** */
@import url("https://fonts.googleapis.com/css2?family=Zen+Maru+Gothic:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,500,700&display=swap&subset=japanese");
html, body, div, header, footer, article, section, aside, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, figure {
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: none;
}

body {
  -webkit-text-size-adjust: 100%;
  line-height: 100%;
}

ol, ul, li {
  list-style: none;
}

img {
  -webkit-backface-visibility: hidden;
  -ms-interpolation-mode: bicubic;
  vertical-align: bottom;
}

ins {
  text-decoration: none;
}

del {
  text-decoration: line-through;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

:focus {
  outline: 0;
}

caption, th, td {
  text-align: left;
  font-weight: normal;
}

.clear, div .clear {
  width: 0;
  height: 0;
  font-size: 0;
  line-height: 0;
  text-indent: -9999px;
  clear: both;
}

/*---clearFix---*/
.clearFix {
  zoom: 1;
}

.clearFix:after {
  content: ".";
  clear: both;
  display: block;
  height: 0;
  visibility: hidden;
}

@media print {
  body {
    zoom: 60%;
  }
}

/*-------------------------------
  flex
-------------------------------*/
/*-------------------------------
  font
-------------------------------*/
/*------------------------------
  position
-------------------------------*/
body {
  font-family: 'Noto Sans JP', "ヒラギノ角ゴ Pro W3", "メイリオ", Meiryo, "游ゴシック体", "Yu Gothic", YuGothic, "ＭＳ Ｐゴシック", sans-serif;
  color: #000000;
  background: #ffffff;
}

* {
  margin: 0;
  padding: 0;
  outline: 0;
}

html * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html, body {
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

p, a {
  font-size: 16px;
  line-height: 1.7em;
  word-break: normal;
  color: #000;
}

@media only screen and (max-width: 640px) {
  p, a {
    font-size: 15px;
  }
}

img {
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: none;
  cursor: pointer;
  backface-visibility: hidden;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

a:hover, a:hover img, .link:hover {
  opacity: 0.7;
  -moz-opacity: 0.7;
}

.anchor {
  padding-top: 80px;
  margin-top: -80px;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .anchor {
    padding-top: 110px;
    margin-top: -110px;
  }
}

.tel_a:hover {
  cursor: text;
  opacity: 1;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .tel_a:hover {
    cursor: pointer;
  }
}

@media only screen and (max-width: 640px) {
  .tel_a:hover {
    cursor: pointer;
  }
}

.font_b {
  font-weight: bold;
}

.c_org {
  color: #FF8000;
}

.pc {
  display: block !important;
}

.tab {
  display: none !important;
}

.sp {
  display: none !important;
}

.pc_tab {
  display: block !important;
}

.tab_sp {
  display: none !important;
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .pc {
    display: none !important;
  }
  .pc_tab {
    display: block !important;
  }
  .tab {
    display: block !important;
  }
  .tab_sp {
    display: block !important;
  }
  .sp {
    display: none !important;
  }
}

@media only screen and (max-width: 640px) {
  .pc {
    display: none !important;
  }
  .pc_tab {
    display: none !important;
  }
  .tab {
    display: none !important;
  }
  .tab_sp {
    display: block !important;
  }
  .sp {
    display: block !important;
  }
}

.flR {
  float: right;
}

.flL {
  float: left;
}

.clearfix::after {
  content: "";
  display: block;
  clear: both;
}

@media all and (-ms-high-contrast: none) {
  p, a {
    margin-top: 2px;
  }
}

input[type=checkbox]::-ms-check {
  display: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

@keyframes LeftToRight {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.kv_cat {
  animation-duration: 2s;
  animation-name: LeftToRight;
}

@keyframes float {
  0% {
    transform: translateY(0);
  }
  33.33333% {
    transform: translateY(-10px);
  }
  66.66667% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(0);
  }
}

.img_box {
  animation: float 3s linear infinite;
  transform-origin: 50% 50%;
  margin: 1rem 0 !important;
}
